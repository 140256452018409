<template>
  <div class="maxBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex='activeIndex' />
      </div>
    </div>
    <!-- 导航条 -->
    <div class="boxContentBar" v-if="!topicId">
      <div class="navigationBar">
        <div class="curror" @click="goLink('/')">首页</div>
        /
        <div class="hoverActive">全部媒体报道</div>
      </div>
    </div>
    <div v-for="(items, index) in topicArray" :key="items.id">
      <!-- banner区域 -->
      <div class="boxContentBg" :style="{background:'url(http://www.topsoft.com.cn'+items.banner+') no-repeat',backgroundSize:'1920px 200px',backgroundPosition:'50%' }">
        <div class="banner"></div>
      </div>
      <!-- 主新闻区域   -->
      <div class="boxContentNews aloneNews">
        <!-- 新闻区域 -->
        <div class="newsContnet">
          <!-- 滚动轮播区域 -->
          <div class="newsCarousel">
            <div class="swiper-container" :class="returnClass(index, 'swiper')">
              <div class="swiper-wrapper">
                <div
                        class="swiper-slide"
                        v-for="item in items.fileList"
                        :key="item.id"
                >
                  <img :src="item.filePath" alt="" @click="goPics(items.id)" />
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev">
                <img
                        style="width:100%;height:100%"
                        src="../../assets/special/atlas_previous@2x.png"
                        alt=""
                />
              </div>
              <div class="swiper-button-next">
                <img
                        style="width:100%;height:100%"
                        src="../../assets/special/atlas_next@2x.png"
                        alt=""
                />
              </div>
            </div>
            <div class="newsDescrice">
              <div class="newsDescriceBook">
                <img src="../../assets/special/atlas_title_icon@2x.png" alt="" />
              </div>
              <div class="newsDescriceExplain">
                {{ items.title }}
              </div>
              <div class="newsDescricePageNumber">
                <div :class="returnClass(index,'page-')">

                </div>
              </div>
            </div>
          </div>
          <!-- 新闻列表区域  -->
          <div class="newsList">
            <div class="newsItem" v-for="news in items.newsList" :key="news.id"  @click="goNews(news.newsId)">
              <div class="newsDate">
                <div class="newsDateDay">{{utils.formatDate(news.createTime).slice(8,11)}}</div>
                <div class="newsLine"></div>
                <div class="newsYears">{{ utils.formatDate(news.createTime).slice(0,7) }}</div>
              </div>
              <div class="newsDescribe">
                <div class="newsDescribeTop">
                  <div class="newsDescribeTopTitle">
                   {{ news.title }}
                  </div>
                  <div class="newsDescribeTopImage">
                    <img
                            src="../../assets/special/news_list_icon@2x.png"
                            alt=""
                    />
                  </div>
                </div>
                <div class="newsDescribeBottom">
                  {{news.description}}
                </div>
              </div>
              <div class="newsSee">
                <div class="newsSeeExplain">查看详情</div>
                <div class="newsSeeArrow">
                  <img src="../../assets/special/news_list_more@2x.png" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import { getTopicList, getTopicDetail } from '@/api/index'
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
      topicArray: [],
      topicId: this.$route.query.topicId
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created () {
    this.activeIndex = this.$route.query.id
  },
  methods: {
    returnClass(index, pre) {
      return pre + index
    },
    goLink(hash) {
      this.$router.push(hash)
    },
    goNews(id) {
      this.$router.push({ path: '/news', query: { newsId: id}})
    },
    goPics(id) {
      this.$router.push({ path: '/atasPage', query: { topicId: id}})
    },
    initSwiper() {
      this.topicArray.map((item, index) => {
        this.$nextTick(() => {
          new Swiper('.swiper' + index, {
            // 如果需要前进后退按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.page-' + index,
              type: 'fraction',
              renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    '/' +
                    '<span class="' + totalClass + '"></span>';
              },
            }
          })
        })
      })
    },
    topList() {
      getTopicList({
        sort: 'creatTime'
      }).then(res => {
        if(res.data.success) {
          this.topicArray = res.data.result.content
          this.initSwiper()
        }
      })
    },
    topicDetail() {
      getTopicDetail({
        id: this.topicId
      }).then(res => {
        if(res.data.success) {
          this.topicArray = [res.data.result]
          this.initSwiper()
        }
      })
    }
  },
   mounted() {
    if(this.topicId) {
      this.topicDetail()
    } else {
      this.topList();
    }
  }
}
</script>
<style>
 .newsDescricePageNumber .swiper-pagination-current{
    font-size: 32px;
   line-height: 45px;
  }
  .newsDescricePageNumber {
    font-size: 20px;
    line-height: 45px;
    font-weight: 400;
    color: #FFFFFF;
  }
</style>
<style lang="scss" scoped>
@import './currencyTopic.scss';

.aloneNews {
  padding-bottom: 70px  !important;
}
.maxBox {
  background: #fbfbfb;
}
.newsContnet{
  width: 1180px;
  margin: 0 auto;
}
.newsItem{
  cursor: pointer;
  &:hover {
    .newsDescribeTopTitle{
      color: #0F4994!important;
    }
  }
}
</style>
